<template>
  <div class="content md no-padding flex-box vertical">
    <GroupForm ref="addForm" class="add-form-box"></GroupForm>
    <a-space class="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleConfirm">保存</a-button>
    </a-space>
  </div>
</template>

<script>
import GroupForm from "./components/GroupForm.vue";

export default {
  name: "ActivityAdd",
  components: {
    GroupForm,
  },
  methods: {
    handleCancel() {
      this.$router.back();
    },
    handleConfirm() {
      this.$refs.addForm.validate().then((form) => {
        this.$axios({
          url: "/admin/volunteer-group",
          method: "POST",
          data: form,
        }).then(() => {
          this.$message.success("添加成功");
          this.$router.back();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
</style>
